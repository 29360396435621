body {
    padding: 0;
    margin: 0;
    font-family: Helvetica, Arial, sans-serif
}
html, body, #map {
    height: 100%;
    width: 100%;
}


.titleblock {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  padding: 5px 5px 0px 5px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  width: 340px;
  min-height: 60px;
  top:65px;
  left:7px;
}

#titleblock-description {
  margin-top:5px;
  margin-bottom: 5px;
}

#titleblock-head1 {
  font-weight: bold;
  font-size: large;
  padding:5px;
}
#titleblock-head2 {
  padding:5px;
}
#titleblock-description {
  font-size: small;
  padding:5px;
}
.custom-select {
  position: relative;
}

select {
  appearance: none;
  /* safari */
  -webkit-appearance: none;
  /* other styles for aesthetics */
  width: 100%;
  font-size: 0.9rem;
  padding: 0.4em 0.5em 0.4em 0.5em !important;  
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
}
option {
  padding: 0em 0em 0em 0em;  
  margin: 0em 0em 0em 0em;  
}
.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  position: absolute;
  content: "";
  right: 1rem;
  pointer-events: none;
}

.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid black;
  top: 20%;
}

.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid black;
  top: 55%;
}

.label {
  position: relative;
  background-color: rgba(255, 204, 51,1);
  color: black;
  border: 1px solid white;
  border-radius: 4px;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: small;
  font-weight: bold;

  text-align: center;
}

.tag {
  position: relative;
  background-color: rgba(255, 204, 51,1);
  color: black;
  border: 1px solid white;
  border-radius: 4px;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: small;
  font-weight: bold;
  left:15px;
  
}

.tag:before {
  border-right: 6px solid rgba(255, 204, 51,1);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  content: "";
  position: absolute;
  margin-top: -5px;
  left: -6px;
  top: 50%;
}


.measure {
  position: relative;
  background-color: rgba(255, 204, 51,1);
  color: black;
  border: 1px solid white;
  border-radius: 4px;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: small;
  font-weight: bold;
  width:130px;
  left:70px;
  top:-13px;
}

.measure:before {
  border-right: 6px solid rgba(255, 204, 51,1);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  content: "";
  position: absolute;
  margin-top: -5px;
  left: -6px;
  top: 50%;
}


#present-master {
  background-color: rgba(255,200,200,0.6);
  border-radius: 5px;
  position: absolute;
  top: 100px;
  left: 5px;
  padding:10px;
  width:200px;
  height:200px;
  font-size: smaller;
}
.master-button {
  font-size: x-large;
  color: red;
}

#logo {
  position:absolute;
  top:10px;
  left:50px;
  padding:3px;
  background-color: rgba(255,255,255,0.7);
  border-radius: 5px;
}

.enabled {
  background-color: rgba(0, 60, 136, 0.6) !important;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(200, 200, 200);
}

.sidebar-header {
  font-size: 13px !important;
}

.sidebar-divide {
  height:1px !important;
  background-color: rgba(250, 250, 250);

}

.feature-value {
    font-size: small;
    font-variant: small-caps;
}

.legend-item {
  line-height: 0px;
}

.ol-scale-line {
  background: rgba(0, 60, 136, 0.8) !important;
  padding:8px;
  left: 102px !important;
}

.ol-scale-line-inner {
  color: white !important;
  border-color: white !important;
}
.map-scale {
  font-size: x-small;
  background: rgba(0, 60, 136, 0.8);
  padding-top:2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  color: white;
  border-radius: 4px;
  position: absolute;
  left:8px;
  bottom:9px;
  height:14px;
  width: 80px;
}

.ol-mouse-position {
  font-size: x-small;
  background: rgba(0, 60, 136, 0.8);
  padding-top:2px;
  padding-bottom: 2px;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  border-radius: 4px;
  position: absolute;
  left:8px !important;
  bottom:30px !important;
  height:14px !important;
  
  top: initial !important;
  right: initial !important;
}
.layer-switcher {
    font-size: x-small;
    font-variant: small-caps;
    color: darkslategray;
    right: initial !important;
}


.tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: small;
  font-weight: bold;
}
.tooltip-measure {
  opacity: 1;
}
.tooltip-static {
  background-color: rgb(255, 204, 51);
  color: black;
  border: 1px solid white;
}
.tooltip-measure:before,
.tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.tooltip-static:before {
  border-top-color: #ffcc33;
} 
.hidden {
  visibility: hidden;
}
.aboutbox {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  width: 380px;
  height: 120px;
  top:25%;
  left:30%
}
.msgbox {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  min-width: 380px;
  min-height: 100px;
  top:25%;
  left:30%
}
#msgbox-input {
  margin-top:20px;
  margin-left:20px;
}
#msgbox-input a {
  margin-right: 10px;
  color: #707070;
  font-size: 20px;
}
#msgbox-input-box {
  width:300px;
  height:18px;
  border-radius: 2px;
  border-width: 1px;
  border-color: #909090;
  color: #707070;
}

.msgbox-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.msgbox-closer:after {
  content: "✖";
}

.ol-popup {

  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}
.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}



.features h1{
  font-size: 12px;
  margin-bottom: 1px;
  padding-bottom: 0px;
}
.features ul{
  margin:2px;
  padding-left: 25px;
}
.features ul li{
  font-size: 12px;
}
.features{
  font-size: 12px;
}

#layouts-list {
  position: absolute;
  top: 0px;
  left: 0px;
  width:0px;
  height:0px;
  visibility: hidden;
}

#loader-page {
  position: absolute;
  width:100%;
  height:100%;
  z-index: 10000;
  background-color: white;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10000;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.layer-switcher-menu {
  float: right;
}

.jstree-default .jstree-themeicon {
  color: rgba(0, 60, 136, 0.8);
}

.jstree-anchor {
  font-size: small;
}
.vakata-context {
  z-index: 100000;
}
.vakata-context li > a {
  font-size: small;
}

.loader2 {
  border: 5px solid #ffffff; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin2 2s linear infinite;
  bottom:10px;
  left:9px;
  position: absolute;
}

@keyframes spin2 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.photo-overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */   
  max-width: 45%;
  /*width: 100%;*/
  display:block;
  position: fixed; /* Stay in place */
  z-index: 10000; /* Sit on top */
  padding: 50px;
  /*left: 0;*/
  top: 0;
  background-color: rgb(0,0,0); /* Black fallback color */
  background-color: rgba(0,0,0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  /* transition: 0.5s;  0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

.photo-overlay-image {
  overflow-x: hidden;
  width:100%
}
.photo-overlay-close {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 60px;
  text-decoration: none;
  color: #cecece;
}