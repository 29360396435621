.sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 2000;
}

.sidebar.collapsed {
  width: 40px;
}

@media (min-width: 768px) {
  .sidebar {
    top: 6px;
    bottom: 6px;
    transition: width 500ms;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sidebar {
    width: 280px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar {
    width: 300px;
  }
}

@media (min-width: 1200px) {
  .sidebar {
    width: 360px;
  }
}

.sidebar-left {
  right: 0;
}

@media (min-width: 768px) {
  .sidebar-left {
    right: 6px;
  }
}

.sidebar-tabs {
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: rgba(0, 60, 136, 0.5);
}

.sidebar-left .sidebar-tabs {
  right: 0;
}

.sidebar-tabs, .sidebar-tabs>ul {
  position: absolute;
  width: 40px;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.sidebar-tabs>li, .sidebar-tabs>ul>li {
  width: 100%;
  height: 40px;
  color: #fff;
  font-size: 12pt;
  overflow: hidden;
  transition: all 80ms;
}

.sidebar-tabs>li:hover, .sidebar-tabs>ul>li:hover {
  color: #fff;
  background-color: rgba(0, 60, 136, 0.6);
}

.sidebar-tabs>li.active, .sidebar-tabs>ul>li.active {
  color: #fff;
  background-color: #0074d9;
}

.sidebar-tabs>li.disabled, .sidebar-tabs>ul>li.disabled {
  color: rgba(255, 255, 255, 0.4);
}

.sidebar-tabs>li.disabled:hover, .sidebar-tabs>ul>li.disabled:hover {
  background: transparent;
}

.sidebar-tabs>li.disabled>a, .sidebar-tabs>ul>li.disabled>a {
  cursor: default;
}

.sidebar-tabs>li>a, .sidebar-tabs>ul>li>a {
  display: block;
  width: 100%;
  height: 100%;
  line-height: 40px;
  color: inherit;
  text-decoration: none;
  text-align: center;
}

.sidebar-tabs>ul+ul {
  bottom: 0;
}

.sidebar-content {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-left .sidebar-content {
  left: 0;
  right: 40px;
}

.sidebar.collapsed>.sidebar-content {
  overflow-y: hidden;
}

.sidebar-pane {
  display: none;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 10px 20px;
}

.sidebar-pane.active {
  display: block;
}

@media (min-width: 768px) and (max-width: 991px) {
  .sidebar-pane {
    min-width: 265px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar-pane {
    min-width: 350px;
  }
}

@media (min-width: 1200px) {
  .sidebar-pane {
    min-width: 420px;
  }
}

.sidebar-header {
  margin: -10px -20px 0;
  height: 40px;
  padding: 0 20px;
  line-height: 40px;
  font-size: 14.4pt;
  color: #fff;
  background-color: #0074d9;
}

.sidebar-left .sidebar-header {
  padding-left: 40px;
}

.sidebar-close {
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}

.sidebar-left .sidebar-close {
  left: 0;
}

.sidebar {
  background-color: rgba(255, 255, 255, 0.4);
}

@media (min-width: 768px) {
  .sidebar {
    border: 3px solid transparent;
    border-radius: 4px;
  }
}

.sidebar-left {
  border-left: 3px solid transparent;
}

.sidebar-tabs {
  overflow: hidden;
}

@media (min-width: 768px) {
  .sidebar-tabs {
    border-radius: 2px 0 0 2px;
  }
  .collapsed .sidebar-tabs {
    border-radius: 2px;
  }
}

@media (min-width: 768px) {
  .sidebar-content {
    border-radius: 0 2px 2px 0;
  }
}

.sidebar-left~.sidebar-map .ol-rotate, .sidebar-left~.sidebar-map .ol-attribution, .sidebar-left~.sidebar-map .ol-full-screen {
  margin-left: 46px;
}

@media (min-width: 768px) {
  .sidebar-left~.sidebar-map .ol-rotate, .sidebar-left~.sidebar-map .ol-attribution, .sidebar-left~.sidebar-map .ol-full-screen {
    transition: margin-left 500ms;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sidebar-left~.sidebar-map .ol-rotate, .sidebar-left~.sidebar-map .ol-attribution, .sidebar-left~.sidebar-map .ol-full-screen {
    margin-left: 317px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar-left~.sidebar-map .ol-rotate, .sidebar-left~.sidebar-map .ol-attribution, .sidebar-left~.sidebar-map .ol-full-screen {
    margin-left: 402px;
  }
}

@media (min-width: 1200px) {
  .sidebar-left~.sidebar-map .ol-rotate, .sidebar-left~.sidebar-map .ol-attribution, .sidebar-left~.sidebar-map .ol-full-screen {
    margin-left: 472px;
  }
}

@media (min-width: 768px) {
  .sidebar-left.collapsed~.sidebar-map .ol-rotate, .sidebar-left.collapsed~.sidebar-map .ol-attribution, .sidebar-left.collapsed~.sidebar-map .ol-full-screen {
    margin-left: 52px;
  }
}
